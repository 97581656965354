import { FunctionComponent } from "react";
import styles from "./FrameFrame.module.css";

const FrameFrame: FunctionComponent = () => {
  return (
    <div className={styles.frameFrame}>
      <div className={styles.wrapperGroup22}>
        <img
          className={styles.wrapperGroup22Child}
          alt=""
          src="/group-22.svg"
        />
      </div>
      <img className={styles.dicons} alt="" src="/3dicons@2x.png" />
      <div className={styles.frameFrame1}>
        <div className={styles.groupFrame}>
          <div className={styles.textFrame}>
            <div className={styles.howDoWe}>How Do We Help You?</div>
            <div className={styles.letsDoGreat}>
              Lets do great work together
            </div>
          </div>
        </div>
        <div className={styles.frameFrame2}>
          <div className={styles.rectangleShape}>
            <img className={styles.dicons1} alt="" src="/3dicons-1@2x.png" />
          </div>
          <div className={styles.whisperTheMagicContainer}>
            <p className={styles.whisperTheMagic}>
              <span className={styles.whisperThe}>Whisper the</span>
              <span className={styles.span}>{` `}</span>
              <span className={styles.magic}>{`magic `}</span>
            </p>
            <p className={styles.wordsBettercallsalman}>
              <span className={styles.words}>words</span>
              <span className={styles.bettercallsalman}>
                {" "}
                "BetterCallSalman”
              </span>
            </p>
          </div>
        </div>
      </div>
      <div className={styles.frameFrame3}>
        <div className={styles.vectorFrame}>
          <div className={styles.groupFrame1}>
            <div className={styles.vectorVectorVector}>
              <img
                className={styles.vectorVectorVectorChild}
                alt=""
                src="/group-27.svg"
              />
              <img
                className={styles.groupFrameIcon}
                loading="eager"
                alt=""
                src="/vector-51.svg"
              />
              <img
                className={styles.groupFrameIcon1}
                loading="eager"
                alt=""
                src="/vector-51.svg"
              />
              <img
                className={styles.groupFrameIcon2}
                loading="eager"
                alt=""
                src="/vector-51.svg"
              />
            </div>
          </div>
          <div className={styles.improveFrame}>
            <div className={styles.weImproveTheUserExperienceWrapper}>
              <div className={styles.weImproveTheContainer}>
                <span
                  className={styles.weImproveThe}
                >{`We improve the User `}</span>
                <span className={styles.experience}>Experience</span>
              </div>
            </div>
            <div className={styles.weImproveTheBrandImageWrapper}>
              <div className={styles.weImproveTheContainer1}>
                <span
                  className={styles.weImproveThe1}
                >{`We improve the Brand `}</span>
                <span className={styles.image}>Image</span>
              </div>
            </div>
            <div className={styles.weImproveTheContainer2}>
              <span
                className={styles.weImproveThe2}
              >{`We improve the Brand `}</span>
              <span className={styles.position}>Position</span>
            </div>
            <div className={styles.weStrategiesInitiativesContainer}>
              <span className={styles.weStrategies}>{`We Strategies `}</span>
              <span className={styles.initiatives}>Initiatives</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FrameFrame;
