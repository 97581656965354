import { FunctionComponent } from "react";
import FrameComponent3 from "../components/FrameComponent3";
import CommunityMembers from "../components/CommunityMembers";
import FrameComponent2 from "../components/FrameComponent2";
import styles from "./HomePage.module.css";

const HomePage: FunctionComponent = () => {
  return (
    <div className={styles.homePage}>
      <img className={styles.vectorIcon} loading="eager" alt="" />
      <FrameComponent3 />
      <CommunityMembers />
      <div className={styles.ourCommunityMembersComeFroWrapper}>
        <div className={styles.ourCommunityMembers}>
          Our Community members come from
        </div>
      </div>
      <section className={styles.mainLogos}>
        <img
          className={styles.mainSoraresvg}
          loading="eager"
          alt=""
          src="/main--soraresvg@2x.png"
        />
        <img
          className={styles.mainVisasvg}
          loading="eager"
          alt=""
          src="/main--visasvg@2x.png"
        />
        <img
          className={styles.mainNesssvg}
          loading="eager"
          alt=""
          src="/main--nesssvg@2x.png"
        />
        <img
          className={styles.mainSafetyWingsvg}
          loading="eager"
          alt=""
          src="/main--safetywingsvg@2x.png"
        />
        <img
          className={styles.mainUnqorksvg}
          loading="eager"
          alt=""
          src="/main--unqorksvg@2x.png"
        />
        <img
          className={styles.mainUnqorksvg1}
          loading="eager"
          alt=""
          src="/main--unqorksvg-1@2x.png"
        />
      </section>
      <FrameComponent2 />
      <section className={styles.homePageInner}>
        <div className={styles.frameWithRectangleAndGroupWrapper}>
          <div className={styles.frameWithRectangleAndGroup}>
            <div className={styles.rectangleInsideGroup} />
            <img
              className={styles.frameWithRectangleAndGroupChild}
              loading="eager"
              alt=""
              src="/group-48096206@2x.png"
            />
            <img
              className={styles.frameWithRectangleAndGroupItem}
              loading="eager"
              alt=""
              src="/group-48096208.svg"
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default HomePage;
