import { FunctionComponent } from "react";
import styles from "./FrameComponent.module.css";

const FrameComponent: FunctionComponent = () => {
  return (
    <div className={styles.frameWrapper}>
      <div className={styles.frameParent}>
        <div className={styles.frameContainer}>
          <div className={styles.fashionBoyWithYellowJacketParent}>
            <img
              className={styles.fashionBoyWithYellowJacketIcon}
              loading="eager"
              alt=""
              src="/fashionboywithyellowjacketbluepants-1@2x.png"
            />
            <div className={styles.iAmHere}>I Am Here to Make</div>
          </div>
        </div>
        <div className={styles.yourBusinessInteresting}>
          Your Business Interesting
        </div>
      </div>
    </div>
  );
};

export default FrameComponent;
