import { FunctionComponent } from "react";
import styles from "./CommunityMembers.module.css";

const CommunityMembers: FunctionComponent = () => {
  return (
    <section className={styles.communityMembers}>
      <div className={styles.interestGroupsFrameParent}>
        <img
          className={styles.interestGroupsFrame}
          alt=""
          src="/interest-groups-frame@2x.png"
        />
        <img
          className={styles.objectsIcon}
          loading="eager"
          alt=""
          src="/objects.svg"
        />
        <div className={styles.microLearningImpact}>
          <h1 className={styles.microLearningMacro}>
            Micro Learning, Macro Impacts with endless collaboration
          </h1>
          <div className={styles.exploreText}>
            <div className={styles.yourHerosJourney}>
              Your Hero’s Journey starts here!
            </div>
          </div>
          <button className={styles.rectangleParent}>
            <div className={styles.frameChild} />
            <img
              className={styles.interestGroupIcons}
              alt=""
              src="/interest-group-icons@2x.png"
            />
            <div className={styles.explore}>Explore</div>
          </button>
        </div>
        <img className={styles.frameItem} alt="" src="/group-48096207.svg" />
      </div>
    </section>
  );
};

export default CommunityMembers;
