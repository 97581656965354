import { FunctionComponent } from "react";
import styles from "./ImproveUXBrandImageFrame.module.css";

const ImproveUXBrandImageFrame: FunctionComponent = () => {
  return (
    <section className={styles.improveUXBrandImageFrame}>
      <div className={styles.frameWithWeimproveXFrames}>
        <img
          className={styles.dicons}
          loading="eager"
          alt=""
          src="/3dicons@2x.png"
        />
        <div className={styles.frameWithTextAndEllipse}>
          <div className={styles.frameWithOneEllipse}>
            <div className={styles.howDoWeHelpYouFrame}>
              <div className={styles.letsdoGreatWorkTogether} />
              <div className={styles.howDoWe}>How Do We Help You?</div>
            </div>
            <div className={styles.letsDoGreat}>
              Lets do great work together
            </div>
          </div>
        </div>
        <div className={styles.whisperTheMagicWordsBetteWrapper}>
          <div className={styles.whisperTheMagicContainer}>
            <p className={styles.whisperTheMagic}>
              <span className={styles.whisperThe}>Whisper the</span>
              <span className={styles.span}>{` `}</span>
              <span className={styles.magic}>{`magic `}</span>
            </p>
            <p className={styles.wordsBettercallsalman}>
              <span className={styles.words}>words</span>
              <span className={styles.bettercallsalman}>
                {" "}
                "BetterCallSalman”
              </span>
            </p>
          </div>
        </div>
        <div className={styles.weImproveUserExperienceBra}>
          <div className={styles.weImproveTheContainer}>
            <span
              className={styles.weImproveThe}
            >{`We improve the User `}</span>
            <span className={styles.experience}>Experience</span>
          </div>
        </div>
        <div className={styles.weImproveUserExperienceBra1}>
          <div className={styles.weImproveTheContainer1}>
            <span
              className={styles.weImproveThe1}
            >{`We improve the Brand `}</span>
            <span className={styles.image}>Image</span>
          </div>
        </div>
        <div className={styles.weImproveUserExperienceBra2}>
          <div className={styles.weImproveTheContainer2}>
            <span
              className={styles.weImproveThe2}
            >{`We improve the Brand `}</span>
            <span className={styles.position}>Position</span>
          </div>
        </div>
        <div className={styles.weImproveUserExperienceBra3}>
          <div className={styles.weStrategiesInitiativesContainer}>
            <span className={styles.weStrategies}>{`We Strategies `}</span>
            <span className={styles.initiatives}>Initiatives</span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ImproveUXBrandImageFrame;
