import { FunctionComponent } from "react";
import styles from "./FrameWithTextAndEllipse.module.css";

const FrameWithTextAndEllipse: FunctionComponent = () => {
  return (
    <div className={styles.frameWithTextAndEllipse}>
      <div className={styles.homeAboutCategoriesFrame} />
      <div className={styles.downloadCVFrame} />
      <div className={styles.rectangleFrame} />
      <div className={styles.frameWithFourEllipses} />
      <div className={styles.vectorFrames}>
        <img
          className={styles.vectorVectorVectorVector}
          loading="eager"
          alt=""
          src="/vector-5.svg"
        />
        <img
          className={styles.vectorVectorVectorVector1}
          loading="eager"
          alt=""
          src="/vector-5.svg"
        />
        <img
          className={styles.vectorVectorVectorVector2}
          loading="eager"
          alt=""
          src="/vector-5.svg"
        />
        <img
          className={styles.vectorVectorVectorVector3}
          loading="eager"
          alt=""
          src="/vector-5.svg"
        />
        <img className={styles.vectorFramesChild} alt="" src="/group-21.svg" />
      </div>
    </div>
  );
};

export default FrameWithTextAndEllipse;
