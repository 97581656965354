import { FunctionComponent } from "react";
import styles from "./FrameComponent3.module.css";

const FrameComponent3: FunctionComponent = () => {
  return (
    <header className={styles.frameParent}>
      <div className={styles.learnWrapper}>
        <img
          className={styles.learnIcon}
          loading="eager"
          alt=""
          src="/learn.svg"
        />
      </div>
      <div className={styles.nav}>
        <div className={styles.community}>Community</div>
        <div className={styles.getInvolved}>Get involved</div>
        <div className={styles.careers}>Careers</div>
        <div className={styles.projects}>Projects</div>
      </div>
      <div className={styles.discordIcon}>
        <button className={styles.discord}>
          <img className={styles.vectorIcon} alt="" src="/vector-1.svg" />
        </button>
        <button className={styles.link}>
          <div className={styles.joinUs}>Join Us</div>
        </button>
      </div>
    </header>
  );
};

export default FrameComponent3;
