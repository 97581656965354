import { FunctionComponent } from "react";
import styles from "./FrameComponent2.module.css";

const FrameComponent2: FunctionComponent = () => {
  return (
    <section className={styles.navWrapper}>
      <div className={styles.nav}>
        <button className={styles.link}>
          <div className={styles.learningCircle}>Learning circle</div>
        </button>
        <div className={styles.navInterestGroupsGigWorks}>
          <div className={styles.interestGroups}>Interest Groups</div>
        </div>
        <div className={styles.navInterestGroupsGigWorks1}>
          <div className={styles.gigWorks}>Gig Works</div>
        </div>
        <div className={styles.projects}>Projects</div>
      </div>
    </section>
  );
};

export default FrameComponent2;
