import { FunctionComponent } from "react";
import LinkFrame from "../components/LinkFrame";
import FrameFrame from "../components/FrameFrame";
import styles from "./MacBookPro14.module.css";

const MacBookPro14: FunctionComponent = () => {
  return (
    <div className={styles.macbookPro141}>
      <section className={styles.topFrame}>
        <div className={styles.wrapperGroup20Parent}>
          <div className={styles.wrapperGroup20}>
            <img
              className={styles.wrapperGroup20Child}
              alt=""
              src="/group-20@2x.png"
            />
          </div>
          <div className={styles.better}>BETTER</div>
          <div className={styles.call}>CALL</div>
          <h2 className={styles.salman}>Salman</h2>
          <img
            className={styles.vector2Stroke}
            loading="eager"
            alt=""
            src="/vector-2-stroke1.svg"
          />
          <div className={styles.weAreComing}>We are Coming Soon!!!</div>
          <LinkFrame />
          <div className={styles.emptyFrame}>
            <img
              className={styles.emptyFrameChild}
              loading="eager"
              alt=""
              src="/group-101.svg"
            />
          </div>
          <div className={styles.vectorFrame}>
            <img
              className={styles.textFrameIcon}
              loading="eager"
              alt=""
              src="/vector-51.svg"
            />
          </div>
        </div>
        <FrameFrame />
      </section>
      <section className={styles.constructionMessage}>
        <div className={styles.theMagicIs}>
          The Magic is under construction We are coming soon.
        </div>
      </section>
      <section className={styles.frameWithText}>
        <img
          className={styles.image17Icon}
          loading="eager"
          alt=""
          src="/image-17@2x.png"
        />
        <footer className={styles.vectorLine}>
          <img
            className={styles.creatorLabelIcon}
            loading="eager"
            alt=""
            src="/vector-9.svg"
          />
          <i className={styles.aSaloomsCreation}>A Salooms Creation</i>
        </footer>
      </section>
    </div>
  );
};

export default MacBookPro14;
