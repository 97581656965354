import { FunctionComponent } from "react";
import styles from "./FrameComponent1.module.css";

const FrameComponent1: FunctionComponent = () => {
  return (
    <header className={styles.homeAboutCategoriesPricingWrapper}>
      <div className={styles.homeAboutCategoriesPricing}>
        <div className={styles.home}>
          <div className={styles.about}>
            <div className={styles.home1}>{`Home `}</div>
            <div className={styles.about1}>About</div>
            <div className={styles.categories}>Categories</div>
            <div className={styles.pricing}>Pricing</div>
          </div>
          <button className={styles.link}>
            <div className={styles.divlabel} />
            <div className={styles.downloadCv}>Download CV</div>
          </button>
        </div>
        <div className={styles.homeAboutCategoriesPricingChild} />
      </div>
    </header>
  );
};

export default FrameComponent1;
