import { FunctionComponent } from "react";
import styles from "./LinkFrame.module.css";

const LinkFrame: FunctionComponent = () => {
  return (
    <div className={styles.linkFrame}>
      <header className={styles.homeAboutCategoriesPricingParent}>
        <div className={styles.homeAboutCategoriesPricing}>
          <div className={styles.home}>{`Home `}</div>
          <div className={styles.about}>About</div>
          <div className={styles.categories}>Categories</div>
          <div className={styles.pricing}>Pricing</div>
        </div>
        <button className={styles.link}>
          <div className={styles.divlabel} />
          <div className={styles.downloadCv}>Download CV</div>
        </button>
      </header>
      <div className={styles.linkFrameInner}>
        <div className={styles.frameWrapper}>
          <div className={styles.iAmHereToMakeYourBusinesParent}>
            <div className={styles.iAmHereContainer}>
              <p className={styles.iAmHere}>I Am Here to Make</p>
              <p className={styles.yourBusinessInteresting}>
                Your Business Interesting
              </p>
            </div>
            <img
              className={styles.fashionBoyWithYellowJacketIcon}
              loading="eager"
              alt=""
              src="/fashionboywithyellowjacketbluepants-11@2x.png"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LinkFrame;
