import { FunctionComponent } from "react";
import FrameComponent1 from "../components/FrameComponent1";
import FrameComponent from "../components/FrameComponent";
import FrameWithTextAndEllipse from "../components/FrameWithTextAndEllipse";
import ImproveUXBrandImageFrame from "../components/ImproveUXBrandImageFrame";
import styles from "./Bettercallsalman.module.css";

const Bettercallsalman: FunctionComponent = () => {
  return (
    <div className={styles.bettercallsalman}>
      <section className={styles.frameParent}>
        <img className={styles.frameChild} alt="" src="/group-20@2x.png" />
        <div className={styles.weAreComing}>We are Coming Soon!!!</div>
        <div className={styles.better}>BETTER</div>
        <div className={styles.call}>CALL</div>
        <b className={styles.salman}>Salman</b>
        <img
          className={styles.vector2Stroke}
          loading="eager"
          alt=""
          src="/vector-2-stroke.svg"
        />
        <FrameComponent1 />
        <FrameComponent />
        <div className={styles.frameWithEllipses}>
          <img
            className={styles.frameWithEllipsesChild}
            loading="eager"
            alt=""
            src="/group-10.svg"
          />
        </div>
        <FrameWithTextAndEllipse />
      </section>
      <ImproveUXBrandImageFrame />
      <section className={styles.theMagicIsUnderConstructioParent}>
        <div className={styles.theMagicIs}>The Magic is under construction</div>
        <div className={styles.weAreComingSoonParent}>
          <div className={styles.weAreComing1}>We are coming soon.</div>
          <img
            className={styles.image16Icon}
            loading="eager"
            alt=""
            src="/image-16@2x.png"
          />
        </div>
      </section>
    </div>
  );
};

export default Bettercallsalman;
